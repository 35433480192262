import React, { useEffect, useState } from "react";

import { Button } from "@twilio-paste/core";

export const GrantNotificationPermissionsButton = () => {
  const [permissions, setPermissions] = useState<NotificationPermission | null>(
    () => null
  );

  useEffect(() => {
    async function getInitPermissions() {
      const result = await Notification.requestPermission();
      setPermissions(result);
    }

    getInitPermissions();
  }, []);

  const handleRequest = async () => {
    const result = await Notification.requestPermission();
    setPermissions(result);
  };

  if (permissions === null) {
    <>
      <Button fullWidth variant="secondary" disabled>
        Loading...
      </Button>
    </>;
  }

  if (permissions === "granted") {
    return (
      <>
        <Button fullWidth variant="secondary" disabled>
          Already granted notifications permissions.
        </Button>
      </>
    );
  }

  if (permissions === "denied") {
    return (
      <>
        <Button fullWidth variant="secondary" disabled>
          Notifications permissions denied. Set permissions manually in browser
          to be able to receive notifications.
        </Button>
      </>
    );
  }

  return (
    <>
      <Button fullWidth variant="secondary" onClick={handleRequest}>
        Get Permissions.
      </Button>
    </>
  );
};
